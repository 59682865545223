import React, { useEffect, useState } from "react";

import { WelcomeBack } from "@components";
import { MainLayout } from "@layouts";
import type { ISubAgent } from "@interfaces";

import { Header, List } from "@modules/Agents/components";
import { useInfiniteFetchData } from "@hooks";

const AgentsPage = (): JSX.Element => {
  const { list, loading, nextPage, hasMore, setData } =
    useInfiniteFetchData(`agents/sub-account?`);

  return (
    <MainLayout extra={<WelcomeBack />}>
      <Header data={list ?? []} setData={setData} />
      <List
        data={list ?? []}
        loading={loading}
        nextPage={nextPage}
        hasMore={hasMore}
        setData={setData}
      />
    </MainLayout>
  );
};

export default AgentsPage;
