import React, { useContext, useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { ModalContext } from "@contexts";
import { EModalTypes } from "@constants/modal";
import { ClearSvg } from "@assets/svg";
import type { IAgentListProps, ISubAgent } from "@interfaces";
import { InfiniteScroll } from "@layouts";
import { AddSubAccountSchema, axiosInstance } from "@utils";
import { toast } from "react-toastify";

const List = ({
  data,
  loading,
  nextPage = () => {},
  hasMore,
  setData
}: IAgentListProps): JSX.Element => {
  const modalContext = useContext(ModalContext);
  const [agentsData, setAgentData] = useState([]);

  const editAgent = (agent: ISubAgent): void => {
    setData((prev) => {
      return prev.map((el) => (el.id === agent.id ? { ...el, ...agent } : el));
    });
  };

  const handleDelete = async (id: number): Promise<void> => {
    try {
      const resp = await axiosInstance.delete(`agents/sub-account/${id}`);
      toast.success("Agent deleted");
      setData(data.filter((el: any) => el.id !== id));
    } catch (e) {
      toast.error(e.data?.message ?? "Something went wrong");
    }
  };

  useEffect(() => {
    setAgentData(data);
  }, [data]);

  const infiniteScrollProps = {
    length: data.length,
    getData: () => {
      nextPage();
    },
    hasMore
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>First Name</div>
        <div>Last Name</div>
        <div>Email</div>
        <div> </div>
      </div>

      <InfiniteScroll className={styles.table} {...infiniteScrollProps}>
        {agentsData?.map((agent: any) => (
          <div
            className={styles.row}
            key={agent.id}
            onClick={(e) => {
              modalContext?.handleOpen(EModalTypes.EDIT_AGENT, e, {
                agent: agent,
                editAgent
              });
            }}
          >
            <div>{agent.first_name}</div>
            <div>{agent.last_name}</div>
            <div>{agent.email}</div>
            <div className={styles.close}>
              <ClearSvg
                className={styles.icon}
                onClick={(e) => {
                  e.stopPropagation();
                  modalContext?.handleOpen(EModalTypes.AGREE, e, {
                    onConfirm: (): void => {
                      handleDelete(agent.id);
                    },
                    title: `Are you sure you want to delete ${agent.email}`
                  });
                }}
              />
            </div>
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default List;
