import React, { useContext, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import cn from "classnames";
import { useSelector } from "react-redux";

import { LogoSvg } from "@assets/svg";

import { NAVIGATION_MENU, PROFILE_ROUTE, LOGIN_ROUTE } from "@constants/routes";

import { ModalContext } from "@contexts";
import { EModalTypes } from "@constants/modal";
import { selectUser } from "@store/user/selector";

import type { IHeaderProps } from "../components.types";

import { useAppDispatch, useOutsideClick } from "@hooks";
import { logout } from "@store/user/actions";
import Sidebar from "../Sidebar";

import styles from "./styles.module.scss";

const Header = ({ className }: IHeaderProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const toggleShow = (): void => {
    setShowPopup((prev) => !prev);
  };

  const ref = useOutsideClick(toggleShow, showPopup);
  const modalContext = useContext(ModalContext);

  const onLogout = async (): Promise<any> => {
    await dispatch(logout());
    location.href = LOGIN_ROUTE;
  };

  return (
    <header className={cn(styles.header, className)}>
      <div className={cn("container", styles.header__box)}>
        {user && <Sidebar />}
        <LogoSvg className={styles.header__logo} />
        {user !== null ? (
          <>
            <div className={cn(styles.header__list, styles.mobile)}>
              <div
                className={cn(styles.header__link)}
                onClick={(e) => modalContext?.handleOpen(EModalTypes.CITIES, e)}
              >
                Explore cities we serve
              </div>
              <a href="#">FAQ</a>
              <a href="#">Support</a>
              <a href="#">services</a>
              <a href="#">booking conditions</a>
            </div>
            <div className={styles.header__menu}>
              {NAVIGATION_MENU.filter((nav) => {
                if (user?.is_sub && nav.title === "Agents") return false;
                else return true;
              }).map(({ to, title, icon: Icon }) => (
                <NavLink
                  className={({ isActive }) => (isActive ? styles.active : "")}
                  to={to}
                  key={to}
                >
                  <Icon /> {title}
                </NavLink>
              ))}
            </div>

            <div className={styles.header__profile} onClick={toggleShow}>
              <div className={styles["header__profile-name"]}>
                {user?.first_name}
              </div>
              <div className={styles["header__profile-avatar"]}></div>
              <div
                className={cn(styles.header__popup, showPopup && styles.show)}
                ref={ref}
              >
                <NavLink
                  to={PROFILE_ROUTE}
                  className={styles["header__popup-item"]}
                >
                  Profile settings
                </NavLink>
                <div
                  className={styles["header__popup-item"]}
                  onClick={onLogout}
                >
                  Log out
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </header>
  );
};

export default Header;
