import React, { useContext, useState } from "react";
import { Form, Formik } from "formik";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { CardLayout } from "@layouts";
import { Button } from "@ui";
import { axiosInstance, LoginSchema } from "@utils";
import { useAppDispatch } from "@hooks";
import { setUser } from "@store/user/reducer";
import { BOOKING_ROUTE } from "@constants/routes";
import { ModalContext } from "@contexts";
import { EModalTypes } from "@constants/modal";

import styles from "./styles.module.scss";
import InputFormik from "@ui/Input/InputFormik";

const initialValue: ILoginForm = {
  email: "",
  password: "",
  agent_type: 0,
  device_name: "agent test device"
};
const cookies = new Cookies();

const LoginForm = (): JSX.Element => {
  const modalContext = useContext(ModalContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const onSubmit = async (values: ILoginForm): Promise<void> => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(`agents/token`, values);
      if (
        modalContext?.handleOpenNoneEvent !== undefined &&
        response.data.verification
      ) {
        modalContext?.handleOpenNoneEvent(EModalTypes.CONFIRM_CODE, {
          email: values.email,
          two_step_token: response.data.two_step_token
        });
        toast.success(response.data.message);
        setLoading(false);
      } else {
        if (response.data.status !== "error") {
          dispatch(setUser(response.data.user));
          const d = new Date();
          d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
          navigate(`/${BOOKING_ROUTE}`);
          cookies.set("access_token", response.data.token, {
            path: "/",
            expires: d,
            ...(process.env.REACT_APP_STAGE !== "development" && {
              domain: ".gpu.travel"
            })
          });
        }
      }
    } catch (e) {
      setLoading(false);
      toast.error(e.data.message ?? "Something went wrong");
    }
  };
  return (
    <div className={styles.login__wrapper}>
      <CardLayout className={styles.login}>
        <div className={styles.login__title}> Log in </div>
        <Formik
          initialValues={initialValue}
          onSubmit={onSubmit}
          validationSchema={LoginSchema}
        >
          <Form>
            <InputFormik
              className={styles.login__input}
              placeholder="Email"
              name="email"
            />
            <InputFormik
              className={styles.login__input}
              placeholder="Password"
              name="password"
              password
            />
            <Button
              isLoading={loading}
              className={styles.login__submit}
              type="submit"
              size="full"
            >
              login
            </Button>
          </Form>
        </Formik>
      </CardLayout>
    </div>
  );
};

export default LoginForm;
