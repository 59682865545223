import React, { useEffect, useState } from "react";
import cn from "classnames";

import styles from "./styles.module.scss";
import { CardLayout } from "@layouts";
import { withMask } from "use-mask-input";

import {
  FormikProvider,
  useFormik,
  Form as FormFormik,
  type FormikHelpers
} from "formik";
import type { IFormProps } from "../widgets.types";
import type { ISignupForm } from "@interfaces";
import {
  Button,
  CheckboxFormik,
  CheckboxListFormik,
  InputLabel,
  InputLabelFormik,
  Select
} from "@ui";
import { CountryCodeSelectFormik } from "@modules/Booking/components";
import AutocompleteInput from "@modules/Booking/components/Input/AutocompleteInput";
import { SignupSchema, axiosInstance, removeToken } from "@utils";
import { useAppDispatch, useQuery } from "@hooks";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BOOKING_ROUTE } from "@constants/routes";
import type { ISelectListItem } from "@ui/ui.types";
import type { Result } from "check-password-strength";
import { setUser } from "@store/user/reducer";
import { Cookies } from "react-cookie";

const planList: ISelectListItem[] = [
  {
    label: "Plan Managed",
    value: "plan_managed"
  },
  {
    label: "Self Managed",
    value: "self_managed"
  }
];

const bussinessTypeList: ISelectListItem[] = [
  { value: "abn", label: "ABN" },
  { value: "ndis", label: "NDIS" },
  { value: "mptp", label: "MPTP" }
];

const cookies = new Cookies();

const Form = ({ contact }: IFormProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { refcode, account } = useQuery();
  const [bussinessType, setBussinessType] = useState<ISelectListItem>(
    bussinessTypeList.find(
      (el) => el.value === contact?.business_number_type
    ) ?? bussinessTypeList[0]
  );
  const [passwordStrength, setPasswordStrength] =
    useState<Result<string> | null>(null);

  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const initialValues: ISignupForm = {
    first_name: contact?.first_name ?? "",
    last_name: contact?.last_name ?? "",
    code: contact?.mobile_country_code,
    mobile: contact?.phone ?? "",
    email: contact?.email ?? "",

    company: contact?.company ?? "",
    ABN: contact?.business_number ?? "",
    address: contact?.address_raw ?? null,
    password: "",
    terms: false,
    refcode: "",
    use_for_invoice: contact ? contact.invoice_email === contact.email : true,
    invoice_email: contact?.invoice_email ?? "",
    manage_type: contact
      ? planList.find((el) => el.value === contact.manage_type)
      : undefined
  };

  const loginUser = async (values: ISignupForm): Promise<void> => {
    try {
      const response = await axiosInstance.post(`agents/token`, {
        email: values.email,
        password: values.password,
        agent_type: 0,
        device_name: "agent test device"
      });
      dispatch(setUser(response.data.user));
      const d = new Date();
      d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
      navigate(`/${BOOKING_ROUTE}`);

      cookies.set("access_token", response.data.token, {
        path: "/",
        expires: d,
        ...(process.env.REACT_APP_STAGE !== "development" && {
          domain: ".gpu.travel"
        })
      });
    } catch (e) {
      console.log("[loginUser] error:", e);
    }
  };

  const onSubmit = async (
    values: ISignupForm,
    { setErrors }: FormikHelpers<ISignupForm>
  ): Promise<void> => {
    if (
      values.address === undefined ||
      !passwordStrength ||
      passwordStrength.id < 2
    ) {
      return;
    }
    setLoading(true);
    try {
      const resp = await axiosInstance.post("agents", {
        first_name: values.first_name,
        last_name: values.last_name,
        mobile_country_code:
          typeof values.code !== "string" ? values.code?.code : "",
        mobile: values.mobile,
        email: values.email,
        company: values.company,
        password: values.password,
        password_confirmation: values.password,
        business_number: values.ABN.replace(/ /g, "+"),
        business_number_type: bussinessType.value,
        addr_unit: 0,
        manage_type: values.manage_type?.value,
        addr_streetno: 0,
        addr_streetname:
          values.address?.formatted_address ?? values.address ?? "",
        address_raw: JSON.stringify(values.address),
        addr_suburb: "empty",
        addr_postcode: 0,
        terms: values.terms,
        ref_code: refcode ?? values.refcode,
        signup_type: contact?.signup_type,
        account,
        invoice_email: values.use_for_invoice
          ? values.email
          : values.invoice_email
      });
      toast.success("Created");
      removeToken();
      await loginUser(values);
      console.log(resp.data);
    } catch (e) {
      console.log("[signup] error", e.data);
      toast.error(e.data?.message ?? "Something went wrong");
      if (e.data.errors !== undefined) {
        setErrors({
          email: e.data.errors.email?.[0] ?? "",
          address: e.data.errors.addr_streetname?.[0] ?? "",
          mobile: e.data.errors.mobile?.[0] ?? ""
        });
      }
    }
    setLoading(false);
  };
  const formik = useFormik<ISignupForm>({
    initialValues,
    onSubmit,
    validationSchema: SignupSchema
  });

  useEffect(() => {
    formik.setFieldValue(
      "manage_type",
      bussinessType?.value === "ndis"
        ? planList.find((el) => el.value === contact?.manage_type) ??
            planList[0]
        : undefined
    );
  }, [bussinessType]);
  return (
    <div className={styles.form}>
      <div className={styles.form__message}>
        <p> Hi {contact?.first_name}, </p>
        {contact !== undefined ? (
          <p>
            You have been invited by {contact.sales_person_name} to sign up for
            an account with Get Picked Up.
          </p>
        ) : null}

        <p>
          We have prefilled your information with details we have on file, You
          can review and ensure they are correct, and finalise the sign up
          process below..
        </p>
      </div>
      <CardLayout className={styles.form__card} leftBar>
        <FormikProvider value={formik}>
          <FormFormik>
            <InputLabelFormik
              className={styles.form__input}
              name="first_name"
              label="Fist name"
              required
            />
            <InputLabelFormik
              className={styles.form__input}
              name="last_name"
              label="Last name"
              required
            />
            <div className={styles.form__row}>
              <CountryCodeSelectFormik name="code" />
              <InputLabelFormik
                name="mobile"
                className={styles.form__input}
                label="Office number"
                type="number"
                extra={
                  typeof formik.values.code !== "string"
                    ? `+${formik.values.code?.code ?? ""}`
                    : ""
                }
                required
              />
            </div>
            <InputLabelFormik
              className={styles.form__input}
              name="email"
              label="Email"
              required
            />

            <CheckboxFormik
              className={cn(styles.form__check, styles.margin)}
              name="use_for_invoice"
              label="Use the same email for invoices"
            />

            {!formik.values.use_for_invoice ? (
              <InputLabelFormik
                className={styles.form__input}
                name="invoice_email"
                label="Invoice email"
                required
              />
            ) : null}

            <div className={styles.form__row}>
              <InputLabelFormik
                className={styles.form__input}
                name="company"
                label="Company name"
                required
              />
              <InputLabelFormik
                ref={withMask(
                  bussinessType.value === 0
                    ? "99 999 999 999"
                    : "999999999999999999999999999999999999999999999999999999999999999999999999999999999",
                  { placeholder: "" }
                )}
                className={styles.form__input}
                name="ABN"
                label={bussinessType.label}
                required
                extraBtn={
                  <Select
                    list={bussinessTypeList}
                    setValue={setBussinessType}
                    title=""
                    color="ghost"
                  />
                }
              />
            </div>
            {bussinessType.value === "ndis" ? (
              <CheckboxListFormik
                className={cn(styles.manage)}
                itemClassName={styles.manage__check}
                name="manage_type"
                list={planList}
                disableUncheck
              />
            ) : null}
            <AutocompleteInput
              className={styles.form__input}
              name="address"
              label="Address"
              tag={InputLabel}
              placeholder=""
              required
            />
            {refcode === undefined && (
              <InputLabelFormik
                className={styles.form__input}
                name="refcode"
                label="Referral code"
              />
            )}
            <InputLabelFormik
              setPasswordStrength={setPasswordStrength}
              className={styles.form__input}
              label="Create password"
              name="password"
              type="password"
              required
            />

            <div className={styles.form__bottom}>
              <CheckboxFormik
                className={styles.form__check}
                name="terms"
                label="I agree with GPU Terms and Conditions"
              />
              <Button
                className={styles.form__button}
                color="ghost"
                size="md"
                isLoading={loading}
              >
                Next
              </Button>
            </div>
          </FormFormik>
        </FormikProvider>
      </CardLayout>
    </div>
  );
};

export default Form;
