import { EModalTypes } from "@constants/modal";
import { ModalLayout } from "@layouts";
import React, { useContext } from "react";

import styles from "./styles.module.scss";
import { ModalContext } from "@contexts";
import { BookingStatus } from "@modules/History/components";
import type { IOrder } from "@interfaces";
import type { EBookingType } from "@constants/history";
import { Table } from "@ui";
import type { ITableCol } from "@ui/ui.types";

const data = [
  {
    type: "Booking accepted",
    date: "12 Apr, 12:00",
    name: "Philip Main",
    role: "driver",
    comment: "Added 40 min wait time"
  },
  {
    type: "Booking accepted",
    date: "12 Apr, 12:00",
    name: "Philip Main",
    role: "driver",
    comment: "Added 40 min wait time"
  },
  {
    type: "Booking accepted",
    date: "12 Apr, 12:00",
    name: "Philip Main",
    role: "driver",
    comment: "Added 40 min wait time"
  },
  {
    type: "Booking accepted",
    date: "12 Apr, 12:00",
    name: "Philip Main",
    role: "driver",
    comment: "Added 40 min wait time"
  },
  {
    type: "Booking accepted",
    date: "12 Apr, 12:00",
    name: "Philip Main",
    role: "driver",
    comment: "Added 40 min wait time"
  },
  {
    type: "Booking accepted",
    date: "12 Apr, 12:00",
    name: "Philip Main",
    role: "driver",
    comment: "Added 40 min wait time"
  }
];

const cols: ITableCol[] = [
  {
    dataIndex: "type"
  },
  {
    dataIndex: "date"
  },
  {
    dataIndex: "name"
  },
  {
    dataIndex: "role"
  },
  {
    dataIndex: "comment"
  }
];
const OrderHistoryModal = (): JSX.Element => {
  const modalContext = useContext(ModalContext);

  const order: IOrder = modalContext?.data?.order;

  return (
    <ModalLayout
      className={styles.history}
      type={EModalTypes.ORDER_HISTORY}
      title="History log"
    >
      <div className={styles.history__heading}>
        <div className={styles["history__heading-left"]}>
          <p>Booking: №{order?.oid}</p>
          <p>Created: {order?.tm_as_string}</p>
        </div>
        <BookingStatus status={order?.status as EBookingType} />
      </div>

      <Table data={data} cols={cols} />
    </ModalLayout>
  );
};

export default OrderHistoryModal;
