import React, { useState } from "react";

import { ArrowSvg, CustomersSvg, SearchSvg } from "@assets/svg";
import { useDebounce, useInfiniteFetchData, useOutsideClick } from "@hooks";

import type { ChangeEvent } from "react";
import type { ICustomerListProps } from "../components.types";
import type { ICustomer } from "@interfaces";

import styles from "./styles.module.scss";
import { InfiniteScroll } from "@layouts";

import cn from "classnames";
import { getFullUserName } from "@utils";
const CustomerList = ({
  className,
  setCustomer,
  btnExtra,
  align = "left",
  popup = true,
  initValue = ""
}: ICustomerListProps): JSX.Element => {
  const [searchValue, setSearchValue] = useState<string>(initValue);
  const [search, setSearch] = useState<string>(initValue);
  const [show, setShow] = useState<boolean>(false);

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(e.target.value);
  };

  const onClick = (customer: ICustomer) => () => {
    setCustomer(customer);
    setSearch("");
    setSearchValue(getFullUserName(customer));
    setShow(false);
  };

  const { list, hasMore, nextPage, clearData } =
    useInfiniteFetchData<ICustomer>(
      `customers?${search !== "" ? `by_name=${search}` : ""}`
    );

  useDebounce(
    () => {
      clearData();
      setSearch(searchValue);
    },
    searchValue,
    500,
    true
  );

  const toggleShow = (): void => {
    setShow((prev) => !prev);
  };

  const ref = useOutsideClick(toggleShow, show);
  return (
    <div className={styles.select} ref={ref}>
      {popup ? (
        <div className={cn(styles.select__btn, className)} onClick={toggleShow}>
          {btnExtra !== undefined ? (
            btnExtra
          ) : (
            <>
              <CustomersSvg /> Customer list
            </>
          )}
        </div>
      ) : null}

      <div
        className={cn(
          styles.select__popup,
          styles[align],
          !popup && styles.static,
          show && styles.active
        )}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          className={cn(
            styles.select__search,
            list.length > 0 ? styles.border : ""
          )}
        >
          <SearchSvg className={styles.search} />
          <input
            value={searchValue}
            placeholder="Search"
            onChange={onChange}
            autoFocus
          />
          <ArrowSvg className={styles.arrow} />
        </div>
        <div className={styles.select__list} id="customerList">
          <InfiniteScroll
            length={list.length}
            hasMore={hasMore}
            getData={nextPage}
            scrollableTarget="customerList"
          >
            {list.map((el) => (
              <div
                key={el.cid}
                className={styles["select__list-item"]}
                onClick={onClick(el)}
              >
                {`${el.first_name.toLocaleLowerCase()} ${el.last_name.toLocaleLowerCase()}`}
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default CustomerList;
