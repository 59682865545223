import React, { useEffect, useState } from "react";
import cn from "classnames";

import type { ChangeEvent } from "react";
import type { ISearchListProps } from "@ui/ui.types";
import type { ICustomer, IDriver } from "@interfaces";

import styles from "./styles.module.scss";
import { ArrowSvg, SearchSvg } from "@assets/svg";
import { useDebounce, useInfiniteFetchData } from "@hooks";
import { InfiniteScroll } from "@layouts";

function SearchList<T extends IDriver | ICustomer>({
  type,
  className,
  popup = false,
  placeholder,
  onClick,
  onEmpty = () => {},
  query = "",
  align = "bottom",
  empty
}: ISearchListProps<T>): JSX.Element {
  const [searchValue, setSearchValue] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [focused, setFocused] = useState<boolean>(false);

  const { list, hasMore, nextPage, loading } = useInfiniteFetchData<T>(
    `${type}?${query}${search !== "" ? `&search=${search}` : ""}`
  );

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(e.target.value);
  };

  useDebounce(
    () => {
      setSearch(searchValue);
    },
    searchValue,
    500,
    true
  );

  useEffect(() => {
    if (!loading && list.length === 0) {
      onEmpty(search);
    }
  }, [loading, list.length, search]);

  return (
    <>
      <div className={cn(styles.list, className, popup && styles.popup)}>
        <div
          className={cn(
            styles.list__search,
            list.length > 0 ? styles.border : ""
          )}
        >
          <SearchSvg className={styles.search} />
          <input
            value={searchValue}
            placeholder={placeholder ?? "Search"}
            onChange={onChange}
            onFocus={() => {
              setFocused(true);
            }}
            onBlur={() => {
              setTimeout(() => {
                setFocused(false);
              }, 200);
            }}
          />
          <span className={styles.arrow}>
            <ArrowSvg />
          </span>
        </div>

        <div
          className={cn(
            styles.list__box,
            popup && styles.popup,
            focused && styles.focused,
            styles[align]
          )}
          id="list"
        >
          {!loading && list.length === 0 && empty === undefined ? (
            <div className={styles.list__empty}>Empty</div>
          ) : null}
          <InfiniteScroll
            length={list.length}
            hasMore={hasMore}
            getData={nextPage}
            scrollableTarget="list"
          >
            {list.map((el, index) => (
              <div
                key={index}
                className={styles["list__box-item"]}
                onClick={() => {
                  void onClick(el);
                }}
              >
                {el.display_name ?? `${el.first_name} ${el.last_name}`}
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </div>
      {empty !== undefined && !loading && list.length === 0 ? (
        <div className={styles.list__empty}>{empty}</div>
      ) : null}
    </>
  );
}

export default SearchList;
