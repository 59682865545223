import React, { useState, useContext } from "react";

import type { ICancelBookingProps } from "../widgets.types";
import type { ISelectListItem } from "@ui/ui.types";

import { axiosInstance, getFullUserName } from "@utils";

import styles from "./styles.module.scss";
import { Select, Button, InfoBox } from "@ui";
import { toast } from "react-toastify";
import { ModalContext } from "@contexts";
import { useSelector } from "react-redux";
import { selectUser } from "@store/user/selector";
import { EModalTypes } from "@constants/modal";
import { OrderInfo } from "@components";
import { InfoSvg } from "@assets/svg";
import { useFetchData } from "@hooks";
import type { ICancelReason } from "@interfaces";

const CancelBooking = ({ order }: ICancelBookingProps): JSX.Element => {
  const modalContext = useContext(ModalContext);
  const user = useSelector(selectUser);
  const [reason, setReason] = useState<ISelectListItem>();
  const [manualReason, setManualReason] = useState<string>("");
  const [name, setName] = useState<ISelectListItem>();
  const [manualName, setManualName] = useState<string>("");
  // const [comment, setComment] = useState<string>("");
  // const [file, setFile] = useState<File>();
  const [isError, setIsError] = useState<boolean>(false);

  const { data } = useFetchData<ICancelReason[]>(
    `crm/cancel-reason?by_availability=agent`
  );
  const reasonList = data?.map<ISelectListItem>((el) => ({
    label: el.title,
    value: el.id
  }));

  const selectedReason = data?.find((el) => el.id === reason?.value);

  const nameList = [
    {
      label: "GPU"
    },
    {
      label: "Passenger"
    },
    {
      label: `Agent ${user ? getFullUserName(user) : ""}`
    },
    {
      label: "Input name manually",
      value: "manually"
    }
  ];

  const removeOrderById =
    modalContext !== null ? modalContext.data?.removeOrderById : {};
  const cancelOrder = async (): Promise<void> => {
    if (!reason || !name || (name.value === "manually" && !manualName)) {
      setIsError(true);
      return;
    }

    const formData = new FormData();
    formData.append(
      "reason",
      (selectedReason?.allow_manual
        ? manualReason || selectedReason.title
        : reason?.label) ?? "reason"
    );
    // formData.append("comment", comment ?? "comment");
    formData.append(
      "cancel_person_name",
      (name?.value === "manually" ? manualName : name?.label) ?? "GPU"
    );
    // if (file !== undefined) {
    //   formData.append("image", file);
    // }
    try {
      await axiosInstance.post(`orders/${order.oid}/cancel`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      removeOrderById(order.oid);

      modalContext?.handleOpen(EModalTypes.CANCEL_STATUS, undefined, order.oid);
    } catch (e) {
      toast.error(e?.data?.message ?? "Something went wrong");
    }
  };

  return (
    <div className={styles.cancel}>
      <OrderInfo order={order} className={styles.cancel__table} />

      <div className={styles.cancel__box}>
        <Select
          className={styles.cancel__select}
          title="Select person who cancel booking"
          list={nameList}
          value={name}
          setValue={(value) => {
            setIsError(false);
            setName(value);
          }}
          size="xl"
        />
        {name?.value === "manually" ? (
          <div className={styles.admin__row}>
            <div className={styles["admin__row-avatar"]}></div>
            <input
              type="text"
              placeholder="Add name"
              value={manualName}
              onChange={(e) => {
                setIsError(false);
                setManualName(e.target.value);
              }}
            />
          </div>
        ) : null}
        {isError && (!name || (name.value === "manually" && !manualName)) ? (
          <div className={styles.admin__error}>This field is required</div>
        ) : null}

        <Select
          className={styles.cancel__select}
          title="Select cancel reason"
          list={reasonList ?? []}
          value={reason}
          setValue={(value) => {
            setIsError(false);
            setReason(value);
          }}
          size="xl"
        />
        {selectedReason?.allow_manual ? (
          <div className={styles.admin__row}>
            <div className={styles["admin__row-avatar"]}></div>
            <input
              type="text"
              placeholder="Add reason"
              value={manualReason}
              onChange={(e) => {
                setIsError(false);
                setManualReason(e.target.value);
              }}
            />
          </div>
        ) : null}
        {isError &&
        (!reason || (reason.value === "manually" && !manualReason)) ? (
          <div className={styles.admin__error}>This field is required</div>
        ) : null}
      </div>

      {!order.modify_available ? (
        <InfoBox
          title={"Are you sure you want to cancel this order?"}
          text={
            <>
              There is currently a <b>${order.cost}</b> cancellation cost for
              this booking
            </>
          }
          icon={InfoSvg}
        />
      ) : (
        <InfoBox
          title={"Are you sure you want to cancel this order?"}
          text={
            "Cancelling this booking will be not charged as a cancellation fee."
          }
          color="primary"
          icon={InfoSvg}
        />
      )}

      <div className={styles.mobile_action_buttons}>
        <Button
          size="full"
          color="ghost"
          onClick={() => {
            modalContext?.handleClose();
          }}
        >
          Close
        </Button>
        <Button size="full" onClick={cancelOrder}>
          Confirm
        </Button>
      </div>
      <div className={styles.action_button}>
        <Button onClick={cancelOrder} size="full">
          Confirm cancel booking
        </Button>
      </div>
    </div>
  );
};

export default CancelBooking;
