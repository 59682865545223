import { useEffect } from "react";
import { Cookies } from "react-cookie";

import { useQuery } from "./useQuery";
import { useAppDispatch } from "./redux";
import { getAgent } from "@store/user/actions";
import { useSelector } from "react-redux";
import { selectUser } from "@store/user/selector";

const cookies = new Cookies();

export const useInitAction = (): void => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const search = useQuery();

  useEffect(() => {
    if (search.token !== undefined) {
      const d = new Date();
      d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
      cookies.set("access_token", search.token, {
        path: "/",
        expires: d,
        ...(process.env.REACT_APP_STAGE !== "development" && {
          domain: ".gpu.travel"
        })
      });

      dispatch(getAgent(search.orders as string));
      return;
    }

    if (!user) {
      dispatch(getAgent());
    }
  }, [search]);
};
