export enum EModalTypes {
  CITIES = "cities",
  REPEAT = "repeat",
  BOOKING_CONDITIONS = "conditions",
  TERMS_AND_CONDITIONS = "terms",
  BOOKING = "booking",
  SET_PAY = "set pay",
  AGREE = "agree",
  CANCEL_STATUS = "cancel-status",
  EDIT_CUSTOMER = "edit-customer",
  TRACK_ORDER = "track-order",
  ORDER_HISTORY = "order-history",
  EDIT_AGENT = "edit-agent",
  CONFIRM_CODE = "confirm-code"
}
