/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from "react";
import cn from "classnames";

import { CardLayout, InfiniteScroll } from "@layouts";
import type { ITableProps } from "@ui/ui.types";

import styles from "./styles.module.scss";
import { Empty } from "..";
import Skeleton from "react-loading-skeleton";

// eslint-disable-next-line
const getData = (obj: any, dataIndex: string | string[]): string => {
  if (typeof dataIndex === "string") {
    return obj[dataIndex];
  } else {
    return dataIndex.reduce((prev, cur) => prev[cur] ?? "", obj);
  }
};
const Table = ({
  data,
  cols,
  size = "md",
  loading = false,
  clear = false,
  infinite = false,
  hasMore = true,
  nextPage = () => {},
  empty = "Empty"
}: ITableProps): JSX.Element => {
  const Tag = infinite ? InfiniteScroll : "div";

  const infiniteScrollProps = {
    length: data.length,
    getData: () => {
      nextPage();
    },
    hasMore
  };

  const initLoading = loading && data.length === 0;
  return (
    <Tag className={styles.table} {...infiniteScrollProps}>
      {data.length === 0 && !loading ? (
        <CardLayout>
          <Empty title={empty} />
        </CardLayout>
      ) : initLoading ? (
        Array.from({ length: 10 }).map((el, index) => (
          <div className={cn(styles.table__row, styles[size])} key={index}>
            {cols.map(({ align = "left", addDataIndex, color }, i) => (
              <div
                className={cn(
                  styles["table__row-item"],
                  styles[align],
                  color !== undefined ? styles[color] : ""
                )}
                key={`${index}-${i}`}
              >
                <p>
                  <Skeleton width="80%" height="20px" borderRadius="20px" />
                </p>

                {addDataIndex !== undefined ? (
                  <p className={styles["table__row-item--add"]}>
                    <Skeleton width="60%" height="16px" borderRadius="20px" />
                  </p>
                ) : null}
              </div>
            ))}
          </div>
        ))
      ) : (
        data.map((el, index) => (
          <div className={cn(styles.table__row, styles[size])} key={index}>
            {cols.map(
              (
                {
                  render,
                  renderAdd,
                  dataIndex,
                  align = "left",
                  addDataIndex,
                  color,
                  btnTitle,
                  onBtnClick,

                  action = null
                },
                i
              ) =>
                dataIndex !== undefined ? (
                  <div
                    className={cn(
                      styles["table__row-item"],
                      styles[align],
                      color !== undefined ? styles[color] : ""
                    )}
                    key={`${index}-${i}`}
                  >
                    {btnTitle !== undefined ? (
                      <p className={styles.table__btn} onClick={onBtnClick}>
                        {btnTitle}
                      </p>
                    ) : null}
                    <div>
                      {render !== undefined
                        ? render(getData(el, dataIndex), el)
                        : getData(el, dataIndex)}
                    </div>
                    {addDataIndex !== undefined ? (
                      <p className={styles["table__row-item--add"]}>
                        {renderAdd !== undefined
                          ? renderAdd(getData(el, addDataIndex), el)
                          : getData(el, addDataIndex)}
                      </p>
                    ) : null}
                  </div>
                ) : (
                  <div
                    className={cn(styles["table__row-item"], styles.action)}
                    key={`${index}-${i}`}
                  >
                    {action !== null ? action(el) : null}
                  </div>
                )
            )}
          </div>
        ))
      )}
    </Tag>
  );
};

export default Table;
